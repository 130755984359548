import React, { Fragment, useEffect, useState } from "react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Lazy,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import {
  Button,
  Progress,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Tooltip,
  Col,
  Row,
} from "reactstrap";
import Header from "../../components/Header";
import { useCountdown } from "../../hooks/useCountdown";

import { SocialMediaContainer } from "../../components/SocialMediaContainer";

import "react-multi-carousel/lib/styles.css";

import discord from "../../assets/images/discord.svg";
import teligram from "../../assets/images/teligram.svg";
import twitter from "../../assets/images/twitter.svg";
import medium from "../../assets/images/medium.svg";

import storediamond from "../../assets/store/storediamond.png";
import storerare from "../../assets/store/storerare.png";
import cc from "../../assets/store/CharacterCard.png";

import backgroundclean from "../../assets/store/background-clean.png";
import buybutton from "../../assets/store/buynow.png";

import { getChestCount, resetChestCount } from "../../store/Store/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { SetPendingHunters } from "../../store/Items/actions";
import { LOCAL_COUNT_KEY } from "../../chain/chainConfig";
import { PassportProvider, usePassport } from "../../context/PassportProvider";

import imxLogo from "../../assets/images/passport_logo_64px.svg";
import StoredetailImx from "./StoredetailImx";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Lazy]);

const OpenChestImxComponent = () => {
  const [labelState, setLabelState] = useState(0);
  const [chestType, setChestType] = useState(2);

  const { address: walletAddress, signer, connect, disconnect } = usePassport();

  const dispatch = useDispatch();

  const Chestcount = useSelector((state) => state.StoreHunter.Chestcount);

  useState(() => {
    const localCount = Number(localStorage.getItem(LOCAL_COUNT_KEY) ?? "0");
    dispatch(SetPendingHunters(localCount));
  }, []);

  useEffect(() => {
    if (walletAddress) {
      if (Chestcount.tier2_items === 0 && Chestcount.tier3_items === 0) {
        setLabelState(1);
      } else {
        setLabelState(2);
      }
    } else {
      setLabelState(0);
    }
  }, [Chestcount, walletAddress]);

  useEffect(() => {
    if (walletAddress) {
      dispatch(getChestCount(walletAddress, true));
    } else {
      dispatch(resetChestCount());
      setLabelState(0);
    }
  }, [walletAddress]);

  return (
    <Fragment>
      <div className="container-x">
        <div className="sortbar">
          <h3> Open Chests</h3>
        </div>
        <span className="border-b" />
        <div className="mt-30 store-card-bg">
          {!walletAddress && (
            <div className="flex justify-center mb-2">
              <button
                className="flex flex-row text-center shadow-inner rounded-md self-center mb-2 px-2 py-1 bg-gradient-to-r from-[#f5bc0c] to-[#9734d9]"
                onClick={connect}
              >
                <span className="text-md font-bold text-center justify-center self-center">
                  Connect Immutable Passport
                </span>
                <img
                  className="w-[28px] self-center ml-2"
                  src={imxLogo}
                  alt="Logo"
                />
              </button>
            </div>
          )}

          {walletAddress && (
            <div className="flex justify-center">
              <button
                className="flex flex-row text-center rounded-md self-center mb-2 px-2 py-1 opacity-70 bg-gray-500 bg-opacity-60"
                onClick={disconnect}
              >
                <span className="text-md font-bold text-center self-center">
                  Disconnect
                </span>
                <img
                  className="w-[28px] self-center ml-2"
                  src={imxLogo}
                  alt="Logo"
                />
              </button>
            </div>
          )}

          <Row gap="4" className="mb-0">
            <Col
              className="open-chest-buy-banner"
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <GetCorrectChest
                chestCount={Chestcount}
                labelState={labelState}
                setLabelState={setLabelState}
                onChestTypeChanged={(chestType) => {
                  console.log("ChestCounterv2 => onChestTypeChanged called");
                  setChestType(chestType);
                }}
              />
              {
                (console.log("labelState:", labelState),
                console.log("Chestcount:", Chestcount))
              }
            </Col>
          </Row>
        </div>
        <div className="mt-30 store-card-bg">
          <h4 className="store-header-h4">What's inside the Chest?</h4>
          <Row gap="4">
            <Col className="mb-4 mb-lg-0" sm={12} md={12} lg={5} xl={5}>
              <Card className=" tiercard store-card-shadow">
                <CardHeader className="store-card-headername d-flex justify-content-between align-items-center">
                  <p className="">HUNTERS PER CHEST</p>
                  <p className="">1</p>
                </CardHeader>
                <CardBody className="store-card-info">
                  <div className="px-2">
                    <p className="store-card-body-title">
                      Genesis Artifact equipped
                    </p>
                    <p className="store-card-body-description">Rare BGEM</p>
                    <p className="store-card-body-description">
                      25% More Earnings
                    </p>
                    <p className="store-card-body-description">
                      Premium Pass for Future Game Content
                    </p>
                    <p className="store-card-body-description">
                      Boost in Boom Power (Tier 3)
                    </p>
                  </div>
                  <div>
                    <img
                      src={storerare}
                      alt="Genesis Artifact"
                      style={{
                        maxWidth: "112px",
                        maxHeight: "112px",
                        width: "120px",
                      }}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm={12} md={12} lg={7} xl={7}>
              <div>
                <img src={cc} alt="Character Cards" />
              </div>
            </Col>
          </Row>
        </div>
        <div className="pb-3 defi-footer">
          <div className="defi-footer-content">
            <a
              href="https://discord.com/invite/boomland"
              target="_blank"
              rel="noreferrer"
            >
              <img src={discord} alt="Discord" />
            </a>
            <a
              href="https://t.me/BoomlandAnnouncements"
              target="_blank"
              rel="noreferrer"
            >
              <img src={teligram} alt="Telegram" />
            </a>
            <a
              href="https://twitter.com/BoomLandGames"
              target="_blank"
              rel="noreferrer"
            >
              <img src={twitter} alt="X" />
            </a>
            <a
              href="https://boomlandgames.medium.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={medium} alt="Medium" />
            </a>
          </div>
        </div>
        <div className="defi-copright">
          © {new Date().getFullYear()} The BoomBit Group. All rights reserved.
        </div>
      </div>
    </Fragment>
  );
};

const GetCorrectChest = ({
  setLabelState,
  labelState,
  chestCount,
  onChestTypeChanged,
}) => {
  const [chestType, setChestType] = useState(2); // Tier = 2 or 3
console.log({chestType})
  //useEffect(() => {
  //console.log("chestCount", chestCount);
  //}, [chestCount]);

  // eslint-disable-next-line default-case
  switch (labelState) {
    case 0:
      return (
        <HasNoChest
          labelState={labelState}
          buttonLabel={"Connect your wallet"}
        />
      );
    case 1:
      return (
        <HasNoChest
          labelState={labelState}
          buttonLabel={"Your wallet is empty"}
        />
      );
    case 2:
      return (
        <StoredetailImx
          labelState={labelState}
          chestCount={chestType === 2 ? chestCount.tier2 : chestCount.tier3}
          onChestTypeChanged={(chestType) => {
            console.log("GetCorrectChest => onChestTypeChanged called");
            setChestType(chestType);
            onChestTypeChanged(chestType);
          }}
          tier={chestType}
        />
      );
  }
  return <></>;
};

const HasNoChest = ({ buttonLabel, labelState }) => {
  return (
    <>
      <div className={`bg-img-openchest v-store-img-show`}>
        <img src={backgroundclean} alt="None" />
      </div>
      <div className="btn-buy-openchest btn-buy-now mb-4 mb-lg-0 d-flex align-items-center text-center justify-content-center v-store-img-show">
        <a
          href="https://magiceden.io/collections/polygon/0xe13cdbe1b619837773de29f316428d7f65e7df9a"
          target="_blank"
          rel="noreferrer"
        >
          <img src={buybutton} alt="Buy Button" />
        </a>
      </div>
      <div className="btn-chest-rectangle mb-4 mb-lg-0 d-flex align-items-center text-center justify-content-center v-store-img-show">
        <div className="btn-chest-rectangle-text mb-4 mb-lg-0 d-flex align-items-center text-center justify-content-center v-store-img-show font-face-bad-blockhead">
          <p> {buttonLabel}</p>
        </div>
      </div>
    </>
  );
};

const OpenChestImx = () => (
  <PassportProvider>
    <OpenChestImxComponent />
  </PassportProvider>
);
export default OpenChestImx;
