import "./MainnetBanner.css";
import React from "react";
import mainnetBannerSmall from "../../assets/home/mainnet-banner-small.jpg";
// import mainnetBannerBig from "../../assets/home/mainnet-banner-big.png";
import mainnetBannerBig from "../../assets/home/hunter-On-chain-Banner-airdrop.png";

import { isMobile } from "react-device-detect";
import mainnetTag from "../../assets/home/mainnet-tag.png";
import hocTag from "../../assets/images/huntersonchainlogo_large.png";
import browserButton from "../../assets/home/web-browser-button.png";
import googlePlayButton from "../../assets/home/google-play-button.png";
import appStoreButton from "../../assets/home/app-store-button.png";

export function MainnetBanner() {
  let storebanner;

  const playStoreLink =
    "https://play.google.com/store/apps/details?id=com.hunters.on.chain.mainnet";
  const webBrowserLink = "https://hoc-imx.boomland.io/";
  const playToAirdropLink = "https://airdrop.boomland.io/";

  const appStoreLink = "https://testflight.apple.com/join/mnB642dv";

  if (isMobile) {
    storebanner = mainnetBannerSmall;
  } else {
    storebanner = mainnetBannerBig;
  }

  return (
    <div className="banner">
      <img src={storebanner} alt="storebanner" />

      <div className="banner-action-container">
        <img
          alt="Mainnet Banner"
          src={mainnetTag}
          style={{ width: "40%", height: "auto", alignSelf: "center" }}
        />
        <img
          alt="hoc logo"
          src={hocTag}
          style={{ width: "70%", height: "auto" }}
        />
        <button className="play-now-button">
          <a href={playToAirdropLink}>
            <p className="play-now-text p-2">PLAY NOW</p>
          </a>
        </button>
        <div className="play-buttons-container">
          <a
            href={webBrowserLink}
            style={{ width: "45%", height: "auto", marginRight: "5%" }}
          >
            <img
              alt="browser button"
              src={browserButton}
              href={webBrowserLink}
            />
          </a>
          <a href={playStoreLink} style={{ width: "45%", height: "auto" }}>
            <img alt="google play button" src={googlePlayButton} />
          </a>
          <a
            href={appStoreLink}
            style={{ width: "45%", height: "auto", marginLeft: "5%" }}
          >
            <img alt="app store button" src={appStoreButton} />
          </a>
        </div>
      </div>
    </div>
  );
}
