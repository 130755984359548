/**
 * @file chest.js
 * @description Holds chest functions
 * @typedef {{
 *  type: number;
 *  level: number;
 *  head: number;
 *  body: number;
 *  acc1: number | null;
 *  acc2: number | null;
 *  rarity: number;
 *  hp: number;
 *  damage: number;
 *  speed: number;
 *  attackSpeed: number;
 *  maxDailyHunterLimit: number;
 *  generatedName: string;
 *  name: string;
 * }} HunterData
 */

import { Contract } from "ethers";
import { POST_HUNTER } from "../helpers/url.helper";
import { getChestItems } from "./chestItems";
import { BASE_URL, immutableConfig } from "../config";

/**
 * @param {import("ethers").Signer} signer
 * @param {(state: string) => void | undefined} _setLastState
 * @returns {Promise<{
 *  success: true,
 *  data: HunterData,
 * } | {
 *  success: false,
 *  message: string
 * }>}
 */
export async function openChestAndGetHunterImx(
  chestType,
  signer,
  _setLastState,
  _incrementPendingHunters,
) {
  if (![2, 3].includes(chestType)) {
    return {
      success: false,
      message: "Invalid Chest Type",
    };
  }

  const setLastState = (state) => {
    if (_setLastState) _setLastState(state);
  };

  const incrementPendingHunters = () => {
    if (_incrementPendingHunters) _incrementPendingHunters();
  };

  setLastState(1);

  const signerAddress = await signer.getAddress();

  const getChestsResponse = await getChestItems(signerAddress, true);

  const { tier3_items, tier2_items } = getChestsResponse;
  const items = chestType === 2 ? tier2_items : tier3_items;
  if (items.length === 0) {
    return {
      success: false,
      message: "Account has no chests",
    };
  }

  const randomItem = items[Math.floor(Math.random() * items.length)];

  const genesisChestContract = chestType === 3 ? new Contract(
    immutableConfig.contracts.genesisTier3.address,
    immutableConfig.contracts.genesisTier3.abi,
    signer
  ) : new Contract(
    immutableConfig.contracts.genesisTier2.address,
    immutableConfig.contracts.genesisTier2.abi,
    signer
  );

  const chestOpeningContract = new Contract(
    immutableConfig.contracts.chestOpening.address,
    immutableConfig.contracts.chestOpening.abi,
    signer
  );

  console.log("items", items);
  console.log("randomItem", randomItem);
  console.log("magicEdenChestOpeningContract", chestOpeningContract);

  setLastState(2);

  try {
    const approveTransaction = await genesisChestContract.approve(
      chestOpeningContract.address,
      String(randomItem),
      { gasLimit: 300000000 }
    );
    await approveTransaction.wait(1);
  } catch (e) {
    console.error(e);
    return {
      success: false,
      message: "Approve transaction failed",
    };
  }

  setLastState(3);

  let openingTransaction = null;

  try {
    openingTransaction = await chestOpeningContract.open(randomItem, chestType, {
      gasLimit: 300000000,
    });
    console.log("openingTransaction", openingTransaction);
    await openingTransaction.wait(1);
  } catch (e) {
    console.error(e);
    return {
      success: false,
      message: "Opening transaction failed",
    };
  }

  if (!openingTransaction) {
    return {
      success: false,
      message: "Opening transaction failed",
    };
  }

  setLastState(4);
  let hunterDataResponse = null;
  try {
    hunterDataResponse = await fetch(immutableConfig.BASE_URL + POST_HUNTER, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        txHash: openingTransaction.hash,
      }),
    }).then((response) => response.json());
  } catch (e) {
    console.error(e);
    return {
      success: false,
      message: "API call failed",
    };
  }

  if (hunterDataResponse.resultCode === 0) {
    console.error(hunterDataResponse);
    return {
      success: false,
      message: "Get hunter from tx failed",
      error: hunterDataResponse.error,
    };
  }

  const hunter = hunterDataResponse?.response?.hunterDetails;
  if (!hunter) {
    return {
      success: false,
      message: "No hunter detail in response",
    };
  }

  incrementPendingHunters();

  return {
    success: true,
    data: hunter,
  };
}
